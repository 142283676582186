<template>
  <div>
    <NavBar class="mb-2"></NavBar>
    <b-container>
      <h1>Posts</h1>
      <Loader v-if="loading"></Loader>
      <div v-else>
        <ul>
          <li v-for="(post,idx) in posts" :key="idx"> <router-link :to="{ name: routeNames.POST_DETAIL,params : {postId: post.id, slug: post.slug}}"> {{post.title}}</router-link></li>
        </ul>
      </div>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>

import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
import Consts from "@/consts";
import WebService from "@/webservice";
import Loader from "@/components/Loader";
export default {
  name: "PostDetail",
  components: {Loader, Footer,NavBar},
  props: {
    postId: String,
    slug: String,
  },
  data() {
    return {
      loading: false,
      routeNames: Consts.ROUTE_NAMES,
      posts: null,
    }
  },
  created() {
    window.scrollTo(0,0);
    this.fetchData()
  },
  methods: {
    fetchData(){
      this.loading = true
      WebService.getPostList().then((res)=>{
        this.posts = res.data
        this.loading = false
      }).catch((err)=> {
        console.error(err)
        this.loading = false
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>

</style>